import React from "react"
import { graphql } from "gatsby"

import { ContactSidebar, Layout, PageSection, SEO } from "../../components"

export default ({ data }) => {
  const { siteMetadata } = data.site

  return (
    <Layout>
      <>
        <SEO title="Contact" />
        <PageSection id="contact-content" theme="light" variant="left">
          <div className="page-section--contact">
            <div className="content--contact">
              <form
                name="contact-form"
                method="POST"
                action="/contact/success"
                data-netlify-honeypot="bot-field"
                data-netlify="true"
                autoComplete="off"
                className="form  contact-form"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact-form" />

                <h2 className="page-section__title">Want to chat?</h2>

                <div className="form__row">
                  <label htmlFor="contact-form__name">
                    Your name:
                    <input
                      type="text"
                      id="contact-form__name"
                      name="name"
                      placeholder="Dan Furze"
                      required
                    />
                  </label>
                </div>

                <div className="form__row">
                  <label htmlFor="contact-form__email">
                    Your email:
                    <input
                      type="email"
                      id="contact-form__email"
                      name="email"
                      placeholder="dan@danfurze.com"
                      required
                    />
                  </label>
                </div>

                <div className="form__row">
                  <label htmlFor="contact-form__enquiry">
                    Enquiry:
                    <textarea
                      id="contact-form__enquiry"
                      name="enquiry"
                      placeholder="Hey Dan&hellip;"
                      required
                    />
                  </label>
                </div>

                <div
                  className="form__recaptcha"
                  data-netlify-recaptcha="true"
                />

                <div className="form__row">
                  <button className="button  form__submit" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>

            <ContactSidebar
              links={siteMetadata.links}
              contact={siteMetadata.contact}
            />
          </div>
        </PageSection>
      </>
    </Layout>
  )
}

export const ContactPageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        links {
          email {
            label
            url
            address
          }
        }
        contact {
          social {
            label
            url
          }
        }
      }
    }
  }
`
